@import "../_variables";

.main-container {
  border: none;
  background-color: white;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  .panel {
    // width: calc(100% - 250px);
    width: 100%;
    height: 100vh;
    // margin-left: 250px;
    overflow: auto;
    padding: 0px;
    border: none;
    background-color: white;
  }

  .body-container {
    padding: 10px;
  }
}

.shimmer {
  position: relative;
  width: 80px;
  height: 30px;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #9b9b9b;
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;

    &--circle {
      border-radius: 50%;
    }
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }

    50% {
      opacity: .3;
    }

    100% {
      opacity: 1;
    }
  }
}
@import "../../variables";


// Sidebar

.meus-pedidos-sidebar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  border: 1px solid map-get($colors, light);
  border-radius: 10px;
  margin-top: 2rem;
  padding-bottom: 1rem;

  hr {
    width: 80%;
  }

  a {
    font-family: map-get($fonts, black);
    color: map-get($colors, darkHover);
    transition: ease-in-out .2s;
  }

  a:hover {
    color: map-get($colors, primaryHover);
  }
}

.user-pic {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 1rem .5rem 0 .5rem;

  h5 {
    font-family: map-get($fonts, black);
    color: map-get($colors, medium);
    font-size: 1.2rem;
    margin: .5rem 0 0 0;
  }

  svg {
    font-size: 2.5rem;
    color: map-get($colors, primaryHover);
  }
}

.central-acordeao {
  margin-bottom: 1rem;

  .card {
    border: none;
    border-bottom: 1px solid map-get($colors, light);
    border-radius: 0;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: map-get($colors, ultraLight);
    padding: 0 1rem 1rem 1rem;
  }
}

.central-acordeao-header {
  background-color: map-get($colors, ultraLight) !important;
  font-family: map-get($fonts, black);
  font-weight: bold;
  color: map-get($colors, darkHover);
  text-align: center;
  cursor: pointer;
  transition: ease-in-out .2s;

  svg {
    font-size: .8rem;
    margin-left: .5rem;
  }
}

.central-acordeao-header:hover {
  color: map-get($colors, primaryHover);
}

.has-collapsed {
  color: map-get($colors, primaryHover) !important;
}


@media only screen and (min-width: 992px) and (max-width: 1550px) {
  .sidebar-col {
    padding: 0 !important;
  }

  .central-acordeao-header {
    font-size: 14px;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
// IMPORTACOES DEFAULT PARA TODO O PROJETO
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,900;1,400;1,900&display=swap');


// DEFINE AS CORES PRIMARIAS PARA O PROJETO
$colors: (
  primary: rgb(44,59,79),
  primaryLight: #747c8b,
  primaryHover: rgb(31,50,105),
  secondary: #5698FC,
  secondaryHover: #3974cc,
  success: #5cb85c,
  danger: #d9534f,
  dark: #333,
  darkHover: #666,
  medium: #AFAFAF,
  light: #D9D9D9,
  ultraLight: #f1f1f1,
  semiLight: #e0e0e0
);

$fonts: (
  black: 'Roboto',
  bold: 'Merriweather',
);

$theme-colors: (
    "primary": map-get($colors, secondary),
    "secondary": map-get($colors, primary),
    "info": tomato,
    "danger": teal
);

@import "~bootstrap/scss/bootstrap";
// @import "~slick-carousel/slick/slick.css"; 
// @import "~slick-carousel/slick/slick-theme.css";

.btn-primary-action {
  background-color: map-get($colors, primary) !important;
  font-family: map-get($fonts, black);
  color: white !important;
  border: none !important;
  border-radius: 10px !important;
  font-size: 1rem !important;
}
.btn-primary-action:hover {
  background-color: map-get($colors, primaryHover) !important;
}

.btn-secondary-action {
  font-family: map-get($fonts, black) !important;
  color: map-get($colors, secondary) !important;
  font-size: 1rem !important;
}
.btn-secondary-action:hover {
  color: map-get($colors, primaryHover) !important;
  text-decoration: none !important;
}

.btn-outlined-action {
  border: 1px solid map-get($colors, primary) !important;
  color: map-get($colors, primary) !important;
  border-radius: 200px !important;
  width: auto !important;
}
.btn-outlined-action:hover {
  background-color: map-get($colors, primaryHover) !important;
  color: white !important;
}

.icon {
  margin-right: 5px !important;
  padding: 0px !important;
}

.shimmer {
  padding: 15px 0px;
}

.msgError {
  margin: 5px 0px 10px 0px;
}

.heading-border {
  width: 200px;
  // background-image: url('../src/assets/Images/span-bg.png');
  background-position-x: center;
  background-repeat: no-repeat;
  padding-bottom: 15px;
}

.input-border {
  border: solid 1px map-get($colors, light) !important; 
}

.color-primaryHover {
  color: map-get($colors, primaryHover) !important;
}

.color-secondary {
  color: map-get($colors, secondary) !important;
}

.loading-content {
  font-family: map-get($fonts, black);
  color: map-get($colors, secondary);
  margin: 30px;
}

body {
  margin: auto;
}

h1 {
  font-family: map-get($fonts, black);
  color: map-get($colors, primaryHover);
  font-size: 1.8rem;
  font-weight: bold !important;
}

h2 {
  font-family: map-get($fonts, black);
  color: map-get($colors, primaryHover);
  font-size: 1.3rem;
  font-weight: bold !important;
}

h3 {
  font-family: map-get($fonts, black);
  color: map-get($colors, secondary) !important;
  font-size: 18px;
  font-weight: bold;
}

h4 {
  font-family: map-get($fonts, black) !important;
  font-weight: bold !important;
}

// :active {
//   border: map-get($colors, primary) !important;
// }